/*---------------------------------------
page-top
----------------------------------------*/
.pg-top {
    & .l-footer__copyright { //フロートボタン用
        padding-bottom: 19vw;
    }
}

.pg-topMv {
    &__performance {
        position: relative;

        &__count {
            position: absolute;
            bottom: 4vw;
            left: 2.8vw;
            border-radius: 3px;
            background: rgba(255, 255, 255, 0.7);
            padding: 1.33vw;
            line-height: 1.3;
        }
    }
}

.pg-allButton {
    margin: 0 0 0 auto;
    padding: 1vw;
    width: 9rem;
    background: #fff;
    display: block;
    text-align: center;
    text-decoration: none;
    color: #333;
}

/*--accordion--*/
.pg-accordion input[type=checkbox] {
    opacity: 0;
    position: absolute;
}

.pg-accordion input[type=checkbox] + label {
    background: url('/assets/sp/images/accordion_open.png') 97% 50% no-repeat;
    background-size: 7.8% auto;
}

.pg-accordion input[type=checkbox] + label.active {
    background: url('/assets/sp/images/accordion_close.png') 97% 50% no-repeat, #EEE;
    background-size: 7.8% auto;
}

.pg-accordion__item {
    display: none;
}

.pg-marker--blue {
    position: relative;
    padding-left: 5.5vw;

    &:before {
        content: "";
        width: 4px;
        height: 1.3em;
        display: block;
        background-color: #3D62AC;
        position: absolute;
        left: 3%;
        top: 31%;
    }
}

.pg-searchBox {
    padding-bottom: 4.9vw;

    &__inputArea {
        display: flex;
        justify-content: space-between;

    }

    &__input {
        width: 64.26vw;
        background: url('/assets/sp/images/icon_search.png') 4% 50% no-repeat, #fff;
        background-size: 8.2%;
        padding-left: 3.7rem;
    }

    &__button {
        width: 25.33vw;
        padding: 2.1vw 0;
    }
}

.pg-information {
    margin: 2.66vw;
}

.pg-sliderBox {
    background-color: #def2fe;
    padding: 0 2.66vw 2vw;

    &__head {
        display: flex;
        align-items: center;
        padding: 2.66vw 0 2.66vw;
        font-size: 1.5rem;
        color: #3d62ac;
        font-weight: bold;
        width: 100%;
        line-height: 1.6;
    }

    &__cont {
        padding: 0 5.33vw;
    }

    &__cont--w {
        background-color: #fff;
        padding: 2.66vw 5.33vw 4vw;

        & a {
            display: block;
            min-height: 3.6em;
        }
    }

    &__arrow {
        top: 30%;
    }

    &__arrow--feat {
        top: 43%;
    }

    &__button {
        padding: 3.2vw;
        background: #d4d4d4;
        width: 17.33vw;
        color: #333;
        text-align: center;
        display: block;
        border-radius: .2rem;
        box-shadow: 0 0.4rem 0 #aaa;
        line-height: 1;
        z-index: 20;
    }

    &__prev {
        position: absolute;
        left: 0;
    }

    &__next {
        position: absolute;
        right: 0;
    }

    &__wrapper {
        margin-bottom: 1.33vw;
    }
}

//swiperカスタム用(infomation)
//ボタン有りの場合
.pg-sliderBox__container {
    padding-bottom: 11.6vw;
}

.pg-sliderBox__container .swiper-pagination-fraction {
    bottom: 2.66vw;
    color: #777;
}

//ボタン無しの場合
.pg-sliderBox__container--noButton {
    padding-bottom: 9.6vw;
}

.pg-sliderBox__container--noButton .swiper-pagination-fraction {
    bottom: 0;
    color: #777;
}

.pg-sliderFeat {
    margin: 0 2.66vw 8vw;

    &__cont {
        width: 45.33vw;
        border: 1px solid #AAA;

        &__linkArea {
            color: #333;
            text-decoration: none;
            display: block;
            padding: 2vw 2vw 4vw;
        }

        &__title {
            overflow: hidden;
            font-size: 1.5rem;
            font-weight: bold;
            line-height: 6vw;
            max-height: calc(6vw * 2);
            min-height: calc(6vw * 2);
        }

        &__pic {
            width: 100%;
            height: 25.86vw;
            overflow: hidden;

            & img {
                width: 100%;
            }
        }

        &__text {
            font-size: 1.5rem;
            overflow: hidden;
            line-height: 6vw;
            max-height: calc(6vw * 6);
            min-height: calc(6vw * 6);
        }
    }

}

.pg-headline {
    &--pink {
        color: #fff;
        background: #fb7873;
    }
}

.pg-counselingArea {
    margin-bottom: 5.33vw;
    background: url(/assets/sp/images/top/flow_top.png) center 3.7% no-repeat #f9f6e8;
    background-size: 94.66vw;
    padding-bottom: 6.66vw;
    @supports (background-image: url(/assets/sp/images/top/flow_top.webp)) {
        background-image: url(/assets/sp/images/top/flow_top.webp);
    }

    & h3 {
        margin-bottom: 4vw;
        text-align: center;
        line-height: 1.5;
    }

    &__box {
        margin: 42.5vw 2.66vw 4vw;
        padding: 4vw;
        background: #fff;
    }

    &__points {
        margin: 0 2.66vw 4vw;
        border: 1px solid #fb7873;
        background: #ffeeee;

        & li {
            border-bottom: 1px solid #fb7873;
            padding: 2.66vw 2.66vw 2.66vw 5.6vw;
            display: flex;
            align-items: center;

            &:last-child {
                border-bottom: none;
            }
        }

        & p {
            & em {
                color: #f81504;
            }

            & span {
                vertical-align: top;
                font-size: 1rem;
            }
        }
    }

    &__pointsNumber {
        margin-right: 6.93vw;
        color: #fb7873;
        font-weight: bold;
        font-size: 10.26vw;
    }
}

.pg-bannerArea1 {
    & img {
        width: 100%;
    }
}

.pg-flowArea {
    margin: 0 2.66vw 2.66vw;

    & ol {
        margin-bottom: 1.33vw;
    }

    &__title {
        margin: 0 2.66vw;
        background: url(/assets/sp/images/top/arrow.png) top center no-repeat;
        background-size: contain;
        padding-top: 5vw;
        text-align: center;
        line-height: 1.5;
        margin-bottom: 4vw;
    }
}

.pg-flowBox {
    margin-bottom: 8.2vw;
    width: 100%;
    position: relative;

    & ::after {
        content: "";
        margin-left: -4vw;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4vw 6.66vw 0 6.66vw;
        border-color: #cccccc transparent transparent transparent;
        position: absolute;
        bottom: -6vw;
        left: 50%;
    }

    &:last-child {
        margin-bottom: 0;

        ::after {
            border: none;
        }
    }

    &__title {
        padding: 1vw 0 1.73vw;
        background: #3d62ac;
        color: #fff;
        font-weight: bold;
        font-size: 1.85rem;
        text-align: center;
    }

    &__cont {
        background: #fff;
        padding: 2.66vw;
        display: table;
        justify-content: space-between;
    }

    &__pic {
        width: 37.33vw;
        padding-right: 4vw;
        display: table-cell;
        vertical-align: middle;

        & img {
            margin: 0 auto;
        }
    }

    &__pic__1 {
        width: 100%;
    }

    &__pic__2 {
        width: 75%;
    }

    &__pic__3 {
        width: 78%;
    }

    &__pic__4 {
        width: 65%;
    }

    &__text {
        background: #fff;
        display: table-cell;
        vertical-align: middle;

        & span {
            vertical-align: top;
            font-size: 1rem;
        }
    }
}

.pg-detail__button {
    margin: 0 auto;
    padding: 2.66vw 18.4vw;
    font-size: 1.85rem;
    background: #d4d4d4;
    box-shadow: 0 3px 0 #aaa;
}

.pg-stepArea {
    margin: 0 5.32vw 6.66vw;

    &__title {
        line-height: 1.4;
        margin-bottom: 1.33vw;
    }
}

.pg-ctaArea__submit {
    padding: 6.13vw 0 5vw;
    font-size: 1.5rem;
    margin-bottom: 4.13vw;
}

.pg-guideArea {
    margin-bottom: 13.33vw;
}

.pg-guideBox {
    margin: 0 2.66vw 6.66vw;

    & li {
        margin-bottom: 4vw;
        border-radius: 4px;

        & a {
            display: flex;
            flex-wrap: wrap;
            padding: 2.66vw 2.66vw 3.2vw;
            border: 1px solid #aaaaaa;
            border-bottom: 3px solid #aaaaaa;
            text-decoration: none;
            color: #333333;
        }

        &:last-child {
            margin-bottom: 0;
        }

        & h3 {
            width: 100%;
        }
    }

    &__title {
        margin-bottom: 4vw;
        color: #3d62ac;
        font-size: 1.85rem;
        font-weight: bold;
    }

    &__pic {
        display: inline-block;
        width: 34.66vw;
        margin-right: 2.66vw;

        & img {
            width: 100%;
        }
    }

    &__text {
        margin-top: -1.8vw;
        width: 51vw;
    }
}

.newsArea li {
    position: relative;
    padding: 2.66vw;
    border-bottom: 1px solid #aaaaaa;
    display: table;
    background: url(/assets/sp/images/icon_arrow_right.png) 97% center no-repeat;
    background-size: 2.13vw auto;

    &.is-pickup {
        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            background: url(/assets/sp/images/newsArea_pickup.png) no-repeat;
            background-size: contain;
            width: 23.2vw;
            height: 5.33vw;
        }
    }
}

.newsArea li:first-child {
    border-top: 1px solid #aaaaaa;
}

.newsArea li > a {
    width: 38.66vw;
    height: auto;
    position: relative;
    vertical-align: top;
}

.newsArea li > a img {
    width: 100%;
    height: auto;
    display: block;
}

.newsArea li > a:before {
    padding: 0;
    width: 14.4vw;
    height: auto;
    color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    text-align: center;
    display: block;
}

.newsArea li.category-kaigo > a:before {
    content: "介護";
    background-color: rgba(141, 162, 188, 0.9);
}

.newsArea li.category-medicalcare > a:before {
    content: "医療";
    background-color: rgba(152, 136, 181, 0.9);
}

.newsArea li.category-ninchi > a:before {
    content: "認知症";
    background-color: rgba(214, 139, 147, 0.9);
}

.newsArea li.category-health > a:before {
    content: "健康";
    background-color: rgba(222, 154, 71, 0.9);
}

.newsArea li.category-local > a:before {
    content: "施設";
    background-color: rgba(185, 167, 123, 0.9);
}

.newsArea li div {
    padding: 1.33vw 8.66vw 0 2.66vw;
}

.newsArea li div,
.newsArea li > a {
    display: table-cell;
}

.newsArea li div span {
    color: #777777;
    font-size: 1.2rem;
    display: block;
    /* stylelint-disable-next-line */
    color: #999;
    line-height: 1;
    margin-bottom: 1.33vw;
}

.newsArea li div a {
    display: block;
    overflow: hidden;
    font-size: 1.65rem;
    color: #333333;
    text-decoration: none;
}

.pg-newsArea {
    margin-bottom: 13.33vw;

    &__tag {
        margin-bottom: 6.66vw;

        & a {
            text-decoration: none;
            color: #777777;
        }
    }
}

.pg-faqArea {
    margin-bottom: 13.33vw;

    & li {
        border-top: 1px solid #AAAAAA;

        &:last-child {
            border-bottom: 1px solid #AAAAAA;
        }
    }

    & .pg-faqList {
        margin-bottom: 6.66vw;

        &__question {
            padding: 2.66vw 9.06vw 2.5vw 2.66vw;
            background: url(/assets/sp/images/icon_arrow_down.png) right 2.66vw center no-repeat;
            background-size: 3.46vw auto;

            &.is-active {
                background: url(/assets/sp/images/icon_arrow_up.png) right 2.66vw center no-repeat;
                background-size: 3.46vw auto;
            }
        }

        &__category {
            background: #DEF2FE;
            font-size: 1.5rem;
            padding: 0.5vw 1.5vw;
        }

        &__answer {
            display: none;
            background: #EEEEEE;
            padding: 2.66vw 2.66vw 4vw;

            & p {
                margin-bottom: 5.33vw;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.pg-comsArea {
    margin-bottom: 13.33vw;

    & ul {
        margin: 0 2.66vw 0;
        display: flex;
        flex-wrap: wrap;

        & li {
            margin: 0 6.66vw 6.66vw 0;
            width: 28.6%;

            &:nth-child(3n) {
                margin-right: 0;
            }

            & img {
                width: 100%;
            }
        }
    }
}

/* stylelint-disable-next-line */
.pg-bannerArea1 {
    background: #eeeeee;
    padding: 6.66vw;

    & ul {
        & li {
            margin-bottom: 2.66vw;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.pg-bannerArea2 {
    & .swiper-container {
        padding-bottom: 6.8vw;
    }

    & .pg-slider__pic {
        width: 86.4vw;
        margin: 0 auto;
    }

    & .p-slider__button {
        top: 36%;
    }

    & .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 0;
    }
}

.pg-serviceArea {
    background: #eeeeee;
    padding-bottom: 6.67vw;
    & h2 {
        background: #666666;
        color: #ffffff;
    }

    & ul {
        margin: 0 2.66vw 0;
        display: flex;
        flex-wrap: wrap;

        & li {
            & img {
                max-width: 100%;
                height: auto;
            }
        }
    }
}


.pg-mediaArea {
    background: #eeeeee;
    padding-bottom: 4vw;
    margin-bottom: 5.33vw;

    & h2 {
        background: #666666;
        color: #ffffff;
    }

    & ul {
        margin: 0 2.66vw 0;
        display: flex;
        flex-wrap: wrap;

        & li {
            margin: 0 2.93vw 2.66vw 0;
            width: 29.59vw;
            background: #fff;

            &:nth-child(3n) {
                margin-right: 0;
            }

            & img {
                padding: 1.33vw 2.66vw;
                width: 100%;
            }
        }
    }
}

.pg-outsideArea {
    margin: 0 2.66vw 5.33vw;

    & ul {
        display: flex;
        flex-wrap: wrap;

        & li {
            margin: 0 4vw 2.66vw 0;
            width: 45.33vw;

            &:nth-child(2n) {
                margin-right: 0;
            }

            & a {
                display: block;
                border: 1px solid #aaaaaa;

                &.pg-outsideArea__kokusen {
                    padding: 4.66vw 10.4vw;
                }

                &.pg-outsideArea__mhlw {
                    padding: 3.86vw 10.4vw;
                }

                &.pg-outsideArea__roken {
                    padding: 4.93vw 10.4vw;
                }

                &.pg-outsideArea__caravanmate {
                    padding: 5.2vw 10.4vw;
                }

                & img {
                    width: 100%;
                }
            }
        }
    }
}

/* 高級老人ホームバナー */
.pg-contentsBannerArea {
    &__slider {
        margin: 2.66vw 0;
    }
    &__pic {
        margin: 0 auto;
        width: 94.67vw;
    }
    &__arrow {
        top: 50%;
    }
    &__prev {
        // background: url('/assets/sp/images/icon_arrow_left.png') 50% no-repeat;
        // background-size: contain;
        left: 1%;
    }
    &__next {
        // background: url('/assets/sp/images/icon_arrow_right.png') 50% no-repeat;
        // background-size: contain;
        right: 1%;
    }
}

.pg-sliderVoice {
    margin: 0 2.66vw 8vw;
    & > div:first-of-type {
        margin-bottom: 8.6vw;
    }

    &__cont {
        border: 1px solid #aaa;
        border-bottom: 3px solid #aaa;
        border-radius: 4px;

        &__linkArea {
            color: #333;
            text-decoration: none;
            display: block;
            padding: 2vw 2vw 4vw;
        }

        &__title {
            display: flex;
            overflow: hidden;
            font-size: 90%;

            &--prefName {
                color: #003BEB;
                &:before {
                    content: '[';
                }
                &:after {
                    content: ']';
                }
            }

            &--facilityType {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }

        &__facilityArea {
            display: flex;
            align-items: center;
            background: #def2fe;
            padding: 2.26vw 2.66vw;
        }

        &__facilityImage {
            width: 22.6vw;

            & img {
                max-width: 100%;
            }

        }

        &__facilityName {
            width: 64.6vw;
            font-size: 1.5rem;
            line-height: 1.5;
        }

        &__pic {
            width: 100%;
            height: 25.86vw;
            overflow: hidden;

            & img {
                width: 100%;
            }
        }

        &__text {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        &__totalScore {
            display: block;
            padding-bottom: 20px;
            border-bottom: 1px solid #aaa;
            &--ratingWrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 55%;
                margin: 0 auto;
            }
            &--title {
                display: inline-block;
                font-weight: bold;
                text-align: left;
            }
            &--rating {
                unicode-bidi: bidi-override;
                color: #c5c5c5;
                font-size: 23px;
                line-height: 24px;
                width: 75px;
                margin: 0 auto;
                position: relative;
                padding: 0 0 5px 0;
                &-upper {
                    width: 76.5%;
                    color: #FDD835;
                    padding: 0;
                    position: absolute;
                    z-index: 1;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    top: 0;
                    left: 0;
                    overflow: hidden;
                }
                &-lower {
                    padding: 0;
                    display: flex;
                }
            }
            &--score {
                color: #FF4C00;
                font-weight: bold;
                margin-left: 5px;
            }
            &--count {
                display: flex;
                justify-content: center;
                font-size: 75%;
                & span {
                    color: #003BEB;
                }
            }
        }
    }

}
